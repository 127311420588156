var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[(_vm.courseSession && _vm.course)?_c('Breadcrumb',[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{ name: 'courseIntrosList' }}},[_vm._v(" "+_vm._s(_vm.$t("pageTitle.courseIntrosList"))+" ")])],1),_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{
            name: 'CoursesIntroDetail',
            query: { course_id: _vm.course.id }
          }}},[_vm._v(" "+_vm._s(_vm.helper.displayI18nText(_vm.$i18n.locale, _vm.course.title))+" ")])],1),_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{
            name: 'courseSessionsList',
            query: { course_id: _vm.course.id }
          }}},[_vm._v(" 梯次清單 ")])],1),_c('el-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.pageTitle)+" ")])],1)],2):_c('Breadcrumb',[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.$t("pageTitle.sessionClassesList"))+" ")])],1)],2),_c('div',[(_vm.courseSession == null)?_c('h1',[_vm._v(" "+_vm._s(_vm.$t("pageTitle.sessionClassesList"))+" "),_c('router-link',{attrs:{"to":"/create-class/how-to-billing"}},[_c('i',{staticClass:"fas fa-plus action-icon"})])],1):_c('h3',[_vm._v(" "+_vm._s(_vm.pageTitle)+" "),_c('router-link',{attrs:{"to":{
          name: 'courseSessionsEdit',
          params: { id: _vm.$route.params.id }
        }}},[_c('i',{staticClass:"fas fa-edit action-icon"})])],1),_c('hr')]),(_vm.courseSession != null)?_c('div',[_c('el-row',{attrs:{"gutter":8}},[_c('el-card',{staticClass:"box-card"},[_c('el-form',{ref:"form",attrs:{"label-width":"180px"}},[_c('el-form-item',{attrs:{"label":"Course Session Title:"}},[_vm._v(" "+_vm._s(_vm.helper.displayI18nText(_vm.$i18n.locale, _vm.courseSession.title))+" ")]),_c('el-form-item',{attrs:{"label":"Course Session City:"}},[_vm._v(" "+_vm._s(_vm.courseSession.city)+" ")]),_c('el-form-item',{attrs:{"label":"Course Session Address:"}},[_vm._v(" "+_vm._s(_vm.helper.displayI18nText(_vm.$i18n.locale, _vm.courseSession.address))+" ")]),_c('el-form-item',{attrs:{"label":"Course Session Fee:"}},[_vm._v(" "+_vm._s(_vm.courseSession.fee)+" "+_vm._s(_vm.courseSession.fee_currency)+" ")])],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }