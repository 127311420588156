<template>
  <div class="container">
    <Breadcrumb v-if="courseSession && course">
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link :to="{ name: 'courseIntrosList' }">
            {{ $t("pageTitle.courseIntrosList") }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <router-link
            :to="{
              name: 'CoursesIntroDetail',
              query: { course_id: course.id }
            }"
          >
            {{ helper.displayI18nText($i18n.locale, course.title) }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <router-link
            :to="{
              name: 'courseSessionsList',
              query: { course_id: course.id }
            }"
          >
            梯次清單
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Breadcrumb v-else>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ $t("pageTitle.sessionClassesList") }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div>
      <h1 v-if="courseSession == null">
        {{ $t("pageTitle.sessionClassesList") }}
        <router-link to="/create-class/how-to-billing">
          <i class="fas fa-plus action-icon" />
        </router-link>
      </h1>
      <h3 v-else>
        {{ pageTitle }}
        <router-link
          :to="{
            name: 'courseSessionsEdit',
            params: { id: $route.params.id }
          }"
        >
          <i class="fas fa-edit action-icon" />
        </router-link>
      </h3>
      <hr />
    </div>
    <div v-if="courseSession != null">
      <el-row :gutter="8">
        <el-card class="box-card">
          <el-form ref="form" label-width="180px">
            <el-form-item label="Course Session Title:">
              {{ helper.displayI18nText($i18n.locale, courseSession.title) }}
            </el-form-item>
            <el-form-item label="Course Session City:">
              {{ courseSession.city }}
            </el-form-item>
            <el-form-item label="Course Session Address:">
              {{ helper.displayI18nText($i18n.locale, courseSession.address) }}
            </el-form-item>
            <el-form-item label="Course Session Fee:">
              {{ courseSession.fee }} {{ courseSession.fee_currency }}
            </el-form-item>
          </el-form>
        </el-card>
      </el-row>
    </div>
  </div>
</template>

<script>
import { helper } from "@ivy-way/material";
import roleMixin from "@/mixins/role";
import courseApi from "@/apis/course";
import Breadcrumb from "@/components/Breadcrumb";
export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },

  components: {
    Breadcrumb
  },

  mixins: [roleMixin],

  props: [],
  data() {
    return {
      isActive: true,
      pageSize: 15,
      currentPage: 1,
      sessionId: 0,
      classList: [],
      courseSession: null,
      course: null,
      listTotal: 0,
      handleEndClassId: null
    };
  },
  computed: {
    helper() {
      return helper;
    },
    pageTitle() {
      if (!this.courseSession) return this.$t("pageTitle.sessionClassesList");
      return `${this.helper.displayI18nText(
        this.$i18n.locale,
        this.courseSession.title
      )}`;
    }
  },
  watch: {},

  async mounted() {
    await this.loadClasses();
  },

  methods: {
    async loadClasses() {
      let queryParams = {
        course_session_id: this.$route.params.id,
        is_visible: this.isActive,
        page: this.currentPage
      };
      let sessionClassesRes = await courseApi.fetchSessionClassesForList(
        queryParams
      );
      this.classList = sessionClassesRes.data.map(session_class => ({
        ...session_class,
        teacher: session_class.teacher
          ? session_class.teacher
          : { first_name: "", last_name: "" }
      }));
      this.listTotal = sessionClassesRes.total;

      if (this.$route.params.id != null) {
        let courseSessionRes = await courseApi.fetchSession(
          this.$route.params.id
        );
        this.courseSession = courseSessionRes.course_session;

        let courseRes = await courseApi.fetchCourse(
          this.courseSession.course_id
        );
        this.course = courseRes.course;
      }
    }
  }
};
</script>

<style scoped>
.shortcutBtns {
  margin-bottom: 8px;
}

.shortcutBtns > a {
  margin-right: 8px;
}

.teacherList:not(:first-child) {
  border-top: 1px solid #ebeef5;
  display: block;
}
</style>
